/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
// endbower

/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "core/scss/global.scss";
@import "core/directives/ms-card/templates/template-1/template-1.scss";
@import "core/directives/ms-card/templates/template-2/template-2.scss";
@import "core/directives/ms-card/templates/template-10/template-10.scss";
@import "core/directives/ms-card/templates/template-3/template-3.scss";
@import "core/directives/ms-card/templates/template-5/template-5.scss";
@import "core/directives/ms-card/templates/template-6/template-6.scss";
@import "core/directives/ms-card/templates/template-4/template-4.scss";
@import "core/directives/ms-card/templates/template-8/template-8.scss";
@import "core/directives/ms-card/templates/template-7/template-7.scss";
@import "core/directives/ms-stepper/templates/horizontal/horizontal.scss";
@import "core/directives/ms-card/templates/template-9/template-9.scss";
@import "core/directives/ms-stepper/templates/vertical/vertical.scss";
@import "core/theme-options/theme-options.scss";
@import "core/directives/ms-card/ms-card.scss";
@import "core/directives/ms-form-wizard/ms-form-wizard.scss";
@import "core/directives/ms-info-bar/ms-info-bar.scss";
@import "core/directives/ms-masonry/ms-masonry.scss";
@import "core/directives/ms-material-color-picker/ms-material-color-picker.scss";
@import "core/directives/ms-navigation/ms-navigation.scss";
@import "core/directives/ms-nav/ms-nav.scss";
@import "core/directives/ms-responsive-table/ms-responsive-table.scss";
@import "core/directives/ms-search-bar/ms-search-bar.scss";
@import "core/directives/ms-scroll/ms-scroll.scss";
@import "core/directives/ms-stepper/ms-stepper.scss";
@import "core/directives/ms-splash-screen/ms-splash-screen.scss";
@import "core/directives/ms-shortcuts/ms-shortcuts.scss";
@import "core/directives/ms-widget/ms-widget.scss";
@import "core/directives/ms-timeline/ms-timeline.scss";
@import "toolbar/toolbar.scss";
@import "main/arhiva/arhiva.scss";
@import "main/kodovi/kodovi.scss";
@import "main/login/login.scss";
@import "main/podesavanja/podesavanja.scss";
@import "main/skriptaLogin/loginSkripta.scss";
@import "main/skripte/skripte.scss";
@import "main/test/test.scss";
@import "main/testLogin/login.scss";
@import "main/testovi/testovi.scss";
@import "main/testPregled/testPregled.scss";
@import "navigation/layouts/horizontal-navigation/navigation.scss";
@import "navigation/layouts/vertical-navigation/navigation.scss";
@import "navigation/layouts/vertical-navigation-fullwidth-toolbar-2/navigation.scss";
@import "main/kodovi/actionDialog/actionDialog.tmpl.scss";
@import "main/arhiva/actionDialog/actionDialog.tmpl.scss";
@import "toolbar/layouts/content-with-toolbar/toolbar.scss";
@import "toolbar/layouts/horizontal-navigation/toolbar.scss";
@import "toolbar/layouts/vertical-navigation-fullwidth-toolbar-2/toolbar.scss";
// endinjector



md-toast.md-success-toast-theme{
  span {
    color: White;
  }
  .md-toast-content{
    background-color: #4CAF50;
  }
}

md-toast.md-error-toast-theme {
  span {

    color: black;
  }

  .md-toast-content{
    background-color: #F44336;
  }
}

.icon-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}


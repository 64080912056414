.dataTables_wrapper .dataTables_processing {
  position: absolute !important;
  top: 0 !important;
  left: 50% !important;
  width: 100% !important;
  padding-top: 50px !important;
  height: 100% !important;
  margin-left: -50% !important;
  margin-top: -25px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  background-color: white !important;
}
button#new-search {
  background-color: rgb(2,136,209);
  color: white;
  padding: 8px;
  margin: 5px;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
}
button#new-search:hover {
  background-color: rgb(1,89,162);

}

.filter
{
  md-input-container{
    margin: 30px 25px 0;
  }
}
.filterTest{
  md-input-container{
    margin: 50px;
  }
}

table.dataTable.hover tbody tr:hover {
  cursor: pointer;
}
.dataTables_wrapper .dataTables_paginate {
  padding: 0.75em;
}

.dataTables_wrapper .dataTables_info {
  padding: 0.755em;
  padding-bottom: 0px;
}
.dataTables_wrapper .dataTables_filter{
  padding-right: 1em;
}
.dataTables_wrapper .dataTables_length{
  padding:10px 0 0 10px;
}

span.active {
  color: white;
  padding: 8px;
  border-radius: 3px;
  background: rgb(66, 179, 66);
}

span.deactive {
  color: red;
}
button#new-search {
  background-color: rgb(2,136,209);
  color: white;
  padding: 8px;
  margin: 5px;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
}
button#new-search:hover {
  background-color: rgb(1,89,162);

}
